<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            <template v-if="paragraphID">
                {{ sprintf($t('pages.module.paragraph.save.editParagraph'), [paragraph.translate.title ?? '']) }}
            </template>
            <template v-else>{{ $t('pages.module.paragraph.save.newParagraph') }}</template>
        </h2>

        <div class="d-flex flex-wrap my-1">
            <div class="m-0" v-if="form.updateStatus && tabModel != 'questions'">
                <el-select v-model="form.translate.language_id" class="w-150px" :placeholder="$t('common.chooseSelect')" @change="selectedLanguage">
                    <el-option v-for="(language, languageIndex) in languages" :key="languageIndex" :value="language.id" :label="$t('pages.module.paragraph.save.translateCodes.' + translateCodes[language.code])"></el-option>
                </el-select>
            </div>

            <router-link to="/module/paragraph" class="btn btn-primary align-self-center ms-4 back-page-btn">
                <span class="svg-icon svg-icon-2">
                    <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
                </span>
                {{ $t("pages.module.paragraph.title") }}
            </router-link>
        </div>
    </div>

    <el-tabs v-model="tabModel" class="paragraph-tabs">
        <el-tab-pane :label="$t('pages.module.paragraph.save.tabs.generalDetails')" name="general">
            <el-form :model="form" ref="paragraphForm">
                <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.paragraph.cols.title') }}</label>
                                    <el-form-item prop="translate.title" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.translate.title" type="text"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1" v-if="!form.updateStatus">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.paragraph.save.cols.content') }}</label>
                                    <el-form-item prop="translate.content" :rules="$validation.getMessage(['required'])">
                                        <CustomCkeditor :model="form.translate.content" @updateModel="form.translate.content = $event" :enterToBr="true"></CustomCkeditor>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.paragraph.save.cols.tags') }}</label>
                                    <el-form-item prop="translate.tags">
                                        <el-tag v-for="tag in form.translate.tags" :key="tag" closable :disable-transitions="false" @close="handleTagClose(tag)">
                                            {{ tag }}
                                        </el-tag>
                                        <el-input v-if="tags.inputVisible" ref="saveTagInput" v-model="tags.inputValue" class="input-new-tag" size="mini" @keyup.enter="handleTagInputConfirm"></el-input>
                                        <el-button v-else class="button-new-tag" size="small" @click="showTagInput">+ {{ $t('pages.module.paragraph.save.newTag') }}</el-button>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-7" v-if="form.updateStatus">
                            <div class="card-header">
                                <div class="card-title pt-4 pb-4">
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="card-label fw-bolder fs-3 mb-1">{{ $t('pages.module.paragraph.save.editSentence.title') }}</span>
                                        <span class="text-muted mt-1 fw-bold fs-7">{{ $t('pages.module.paragraph.save.editSentence.editSentenceDescription') }}</span>
                                    </h3>
                                </div>
                            </div>
                            <div class="card-body d-flex flex-column p-9">
                                <edit-paragraph :paragraphID="paragraphID"></edit-paragraph>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.paragraph.save.cols.image') }}</label>
                                    <el-form-item prop="image_id">
                                        <el-upload :accept="$root.acceptFileSupported('image', 'image/')" v-bind:class="{'disabled-upload': image.fileList.length > 0}" :on-change="handleChangeImage" :on-remove="handleChangeImage" list-type="picture-card" :file-list="image.fileList" limit="1" :on-preview="handlePictureCardPreview" :auto-upload="false">
                                            <i class="bi bi-plus"/>
                                        </el-upload>
                                        <el-dialog v-model="image.dialogVisible" width="30%">
                                            <img style="width: 100%" :src="image.dialogImageUrl" alt=""/>
                                        </el-dialog>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.paragraph.save.cols.level') }}</label>
                                    <el-form-item prop="data.level_id" :rules="$validation.getMessage(['required'])">
                                        <el-select v-model="form.data.level_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                            <el-option v-for="(level, levelIndex) in levels" :key="levelIndex" :value="level.id" :label="$t('pages.module.paragraph.save.level.' + level.code.toLowerCase())"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.paragraph.cols.readingTime') }}</label>
                                    <el-form-item prop="data.reading_time" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.reading_time" :min="1"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                                    <el-form-item prop="data.sort" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.sort" :min="1"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                    <el-form-item prop="data.active">
                                        <el-radio-group v-model="form.data.active">
                                            <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mt-5">
                                    <el-form-item class="mb-0">
                                        <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                            <span v-if="form.loading" class="indicator-progress">
                                                {{ $t("messages.wait") }}
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus && form.translate.language_id != defaultLanguage.id" :label="$t('pages.module.paragraph.save.tabs.translationSettings')" name="translationSettings">
            <Translation :paragraphID="paragraphID" v-if="tabModel == 'translationSettings'" :languageID="form.translate.language_id"></Translation>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.module.paragraph.save.tabs.questions')" name="questions">
            <Question :paragraphID="paragraphID" v-if="tabModel == 'questions'"></Question>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import CustomCkeditor from "@/components/custom-ckeditor";
import EditParagraph from "@/components/module/paragraph/editParagraph";
import Translation from "@/components/module/paragraph/translation";
import Question from "@/components/module/paragraph/question";

export default {
    name: "_id",
    components: {
        EditParagraph,
        CustomCkeditor,
        Translation,
        Question
    },
    data() {
        return {
            tabModel: 'general',
            form: {
                updateStatus: false,
                loading: false,
                translate: {
                    tags: []
                },
                data: {
                    sort: 1,
                    reading_time: 60,
                    active: true
                },
            },
            paragraph: {
                translate: {}
            },
            image: {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            },
            tags: {
                inputVisible: false,
                inputValue: ''
            },
            translateCodes: {
                EN: 'original',
                TR: 'translated'
            }
        }
    },
    computed: {
        paragraphID() {
            return this.$route.params.id;
        },
        defaultLanguage(){
            return this.$root.getLanguage('code', 'EN');
        },
        levels() {
            return this.$store.state.module.paragraph.level.table.data;
        },
        languages(){
            let languages = this.cloneData(this.$root.languages);
            return languages.reverse();
        }
    },
    created() {
        if (this.paragraphID && !(this.paragraphID > 0)) {
            this.$router.push({
                path: "/module/paragraph"
            });
        }

       this.$store.dispatch('module/paragraph/level/get', {
            page: { pageSize: 9999 }
        });
    },
    mounted() {
        this.form.translate.language_id = this.defaultLanguage.id;

        if (this.paragraphID && this.paragraphID > 0) {
            this.loadParagraph();
        }
    },
    methods: {
        loadParagraph(paragraphID = this.paragraphID) {
            this.resetImageData();

            this.axios.get(this.endpoints['module_paragraph'] + '/' + paragraphID).then((response) => {
                let data = response.data.data;

                let translate = this.resolveDatum(data.translations, this.form.translate.language_id, 'language_id');
                let tags = [];

                data.tags.forEach((tag) => {
                    if (tag.pivot.language_id == this.form.translate.language_id) {
                        tags.push(tag.name);
                    }
                });

                data.translate = Object.assign(translate, {
                    tags: tags
                });

                if(data.reading_time && data.reading_time > 0){
                    data.reading_time /= 60;
                }

                this.paragraph = data;
                this.form.translate = this.cloneData(data.translate);

                this.form.updateStatus = true;
                this.form.data = data;

                if (data.image) {
                    this.image.fileList = [
                        {
                            name: data.image.name,
                            url: data.image.public_url
                        }
                    ]
                }
            })
        },
        onSubmit() {
            this.$refs.paragraphForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.image.uploadList.length > 0) {
                        this.submitFormWithUpload();
                    } else {
                        if (!(this.image.fileList.length > 0)) {
                            this.form.data.image_id = null;
                        }
                        this.submitForm();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload() {
            const formData = new FormData();

            this.image.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.image_id = data[0].id;
                    this.submitForm();
                }, () => {
                    this.form.loading = false;
                }, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm() {
            this.form.loading = true;
            let formData = this.prepareFormData();

            if (this.form.data.id) {
                this.axios.put(this.endpoints['module_paragraph'] + '/' + this.form.data.id, formData).then(response => {
                    this.onResponse(response.data, () => {
                        this.loadParagraph();
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            } else {
                this.axios.post(this.endpoints['module_paragraph'], formData).then(response => {
                    this.onResponse(response.data, () => {
                        let questionID = response.data.data.id;
                        this.$router.push({
                            path: "/module/paragraph/save/" + questionID
                        });

                        this.loadParagraph(questionID);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }
        },
        prepareFormData() {
            let formData = this.cloneData(this.form.data);
            delete formData.tags;

            if(formData.reading_time && formData.reading_time > 0){
                formData.reading_time *= 60;
            }

            return {...this.form.translate, ...formData}
        },
        resetImageData() {
            this.image = {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            }
        },
        handlePictureCardPreview(file) {
            this.image.dialogImageUrl = file.url
            this.image.dialogVisible = true
        },
        handleChangeImage(file, fileList) {
            this.image.fileList = fileList;
            this.image.uploadList = fileList;
        },
        handleTagClose(tag) {
            this.form.translate.tags.splice(this.form.translate.tags.indexOf(tag), 1)
        },
        showTagInput() {
            this.tags.inputVisible = true
            this.$nextTick((_) => {
                this.$refs.saveTagInput.$refs.input.focus()
            })
        },
        handleTagInputConfirm() {
            const inputValue = this.tags.inputValue
            if (inputValue) {
                this.form.translate.tags.push(inputValue)
            }
            this.tags.inputVisible = false
            this.tags.inputValue = ''
        },
        selectedLanguage(){
            let translate = this.cloneData(this.resolveDatum(this.paragraph.translations, this.form.translate.language_id, 'language_id'));
            let tags = [];

            translate.language_id = this.form.translate.language_id;

            this.paragraph.tags.forEach((tag) => {
                if (tag.pivot.language_id == this.form.translate.language_id) {
                    tags.push(tag.name);
                }
            });

            this.form.translate = Object.assign(translate, {
                tags: tags
            });

            if(this.form.translate.language_id == this.defaultLanguage.id){
                this.tabModel = 'general';
            }
        },
    }
}
</script>

<style>

.el-tag {
    margin-right: 10px;
}

.button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
}

.input-new-tag .el-input__inner {
    height: 32px;
    line-height: 32px;
}

.back-page-btn {
    height: 40px;
    line-height: 1.25;
}
</style>
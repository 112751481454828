<template>
    <div class="card" v-if="loadSkeleton">
        <div class="card-body d-flex flex-column p-9">
            <el-skeleton />
        </div>
    </div>
    <el-form v-else :model="form" ref="translationForm">
        <div class="card">
            <div class="card-header">
                <div class="card-title">
                    <h2 class="fw-bolder">{{ $t('pages.module.paragraph.save.translation.title') }}</h2>
                </div>
                <div class="card-toolbar">
                    <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button">
                        <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                        <span v-if="form.loading" class="indicator-progress">
                            {{ $t("messages.wait") }}
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </div>
            <div class="card-body d-flex flex-column p-9">
                <div v-for="(sentence, sentenceIndex) in sentences" :key="sentenceIndex" v-bind:class="sentenceIndex != 0 && 'mt-4'">
                    <div class="fv-row" v-if="sentence.translate.content != line">
                        <div class="fw-bolder mb-5">{{ (sentenceIndex + 1) }}. <span v-html="sentence.translate.content"></span></div>
                        <el-form-item prop="data.content">
                            <CustomCkeditor :model="form.data[sentence.id].content" @updateModel="form.data[sentence.id].content = $event"></CustomCkeditor>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="card-footer py-4 text-end">
                <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button">
                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                    <span v-if="form.loading" class="indicator-progress">
                        {{ $t("messages.wait") }}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
        </div>
    </el-form>
</template>

<script>
import CustomCkeditor from "@/components/custom-ckeditor";

export default {
    props: ['paragraphID', 'languageID'],
    name: "translation",
    components: {
        CustomCkeditor
    },
    data(){
        return {
            line: '{{LINE}}',
            loadSkeleton: true,
            paragraph: {},
            form: {
                loading: false,
                data: {}
            }
        }
    },
    created(){
        this.loadParagraph();
    },
    computed:{
        sentences(){
            let sentences = [];
            if(this.paragraph.sentences && this.paragraph.sentences){
                this.paragraph.sentences.forEach((sentence) => {
                    sentence.translate = this.resolveDatum(sentence.translations, this.$root.getLanguage('code', 'EN').id, 'language_id');
                    sentences.push(sentence);

                    let contentTranslate = this.resolveDatum(sentence.translations, this.languageID, 'language_id').content;
                    this.form.data[sentence.id] = {
                        id: sentence.id,
                        language_id: this.languageID,
                        content: this.cloneData(contentTranslate ?? ""),
                        sort: sentence.sort
                    }
                });
            }

            return sentences;
        }
    },
    methods: {
        loadParagraph(){
            this.loadSkeleton = true;

            this.axios.get(this.endpoints['module_paragraph'] + '/' + this.paragraphID).then((response) => {
                let data = response.data.data;
                this.paragraph = data;
            }).finally(() => {
                this.loadSkeleton = false;
            });
        },
        onSubmit(){
            this.$refs.translationForm.validate((valid) => {
                if (valid) {
                    let formData = this.prepareFormData();
                    this.form.loading = true;

                    this.axios.put(this.endpoints['module_paragraph_sentence_multi_update'], { items: formData }).then(response => {
                        this.onResponse(response.data, () => {
                            this.loadParagraph();
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                }
            });
        },
        prepareFormData(){
            let formData = [];

            for(let index in this.form.data) {
                let data = this.form.data[index];
                if(data.content.trim().length > 1){
                    formData.push(data);
                }
            }

            return formData;
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <div class="card" v-if="skeleton">
        <div class="card-body d-flex flex-column p-9">
            <el-skeleton />
        </div>
    </div>
    <div v-else>
        <div class="d-flex flex-wrap flex-stack my-5">
            <h2 class="fs-2 fw-bold my-2">
                {{ $t('pages.module.paragraph.question.save.' + (localQuestionID ? 'editQuestion' : 'newQuestion')) }}
            </h2>

            <a class="btn btn-primary align-self-center" v-on:click="backQuestion">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
                {{ $t("pages.module.paragraph.question.title") }}
            </a>
        </div>
        <el-form :model="form" ref="questionForm">
            <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body d-flex flex-column p-9">
                            <div class="fv-row mb-1">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.paragraph.question.cols.content') }}</label>
                                <el-form-item prop="translate.content" :rules="$validation.getMessage(['required'])">
                                    <CustomCkeditor :model="form.translate.content" @updateModel="form.translate.content = $event"></CustomCkeditor>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-7" v-if="selectedQuestionType.code == 'NORMAL'">
                        <div class="card-header">
                            <h2 class="card-title fw-bolder">{{ $t('pages.module.paragraph.question.answer.title') }}</h2>
                            <div class="card-toolbar">
                                <button class="btn btn-flex btn-primary" v-on:click="newAnswer" type="button">
                                <span class="svg-icon svg-icon-2">
                                    <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                                </span>
                                    {{ $t('pages.module.paragraph.question.answer.newAnswer') }}
                                </button>
                            </div>
                        </div>
                        <div class="card-body d-flex flex-column p-9" v-if="form.answers.length">
                            <div class="row">
                                <div class="col-md-6" v-for="(answer, answerIndex) in form.answers" :key="answerIndex" :class="[(answerIndex > 1 ? 'mt-md-4' : 'mt-md-0'), answerIndex != 0 && 'mt-4' ]">
                                    <div class="mb-2 d-flex justify-content-between align-items-center">
                                        <div class="text-gray-800 fs-4 fw-bolder">
                                            {{ sprintf($t('pages.module.paragraph.question.answer.titlePattern'), [$root.questionLetters[answerIndex]])}}
                                        </div>

                                        <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteAnswer(answer, answerIndex)">
                                            <template #reference>
                                                <el-button class="removeAnswerButton" type="danger"><i class="bi bi-trash"/></el-button>
                                            </template>
                                        </el-popconfirm>

                                    </div>
                                    <div class="border border-gray-300 pt-5 px-3 pb-0 rounded">
                                        <div class="fv-row mb-1">
                                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.paragraph.question.answer.cols.content') }}</label>
                                            <el-form-item :prop="'answers['+answerIndex+'].translate.content'" :rules="$validation.getMessage(['required'])">
                                                <CustomCkeditor :model="form.answers[answerIndex].translate.content" @updateModel="form.answers[answerIndex].translate.content = $event"></CustomCkeditor>
                                            </el-form-item>
                                        </div>
                                        <div class="fv-row mb-1">
                                            <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                                            <el-form-item :prop="'answers['+answerIndex+'].data.sort'" :rules="$validation.getMessage(['required'])">
                                                <el-input-number v-model="form.answers[answerIndex].data.sort" :min="1"/>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body d-flex flex-column p-9">
                            <div class="fv-row mb-1">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.paragraph.question.save.cols.type') }}</label>
                                <el-form-item prop="data.question_type_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.question_type_id" class="w-100" :placeholder="$t('common.chooseSelect')" :disabled="form.updateStatus" @change="form.data.correct_answer_id = undefined;">
                                        <el-option v-for="(type, typeIndex) in types" :key="typeIndex" :value="type.id" :label="type.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-1" v-if="selectedQuestionType.code == 'IMAGE'">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.paragraph.question.save.cols.externalAnswerCount') }}</label>
                                <el-form-item prop="data.external_answer_count" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.external_answer_count" :min="1" :max="5" @change="form.data.correct_answer_id = undefined"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-1" v-if="(['TRUE_OR_FALSE'].includes(selectedQuestionType.code) || (form.updateStatus && selectedQuestionType.code == 'NORMAL')) && validatedAnswers.length">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.paragraph.question.cols.correctAnswer') }}</label>
                                <el-form-item prop="data.correct_answer_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.correct_answer_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(answer, answerIndex) in validatedAnswers" :key="answer.value" :value="answer.value" :label="answer.label"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-1">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.paragraph.question.save.cols.gain') }}</label>
                                <el-form-item prop="data.gains">
                                    <el-select v-model="form.data.gains" class="w-100" :placeholder="$t('common.chooseSelect')" multiple clearable>
                                        <el-option v-for="(gain, gainIndex) in gains" :key="gainIndex" :value="gain.id" :label="gain.translate.title"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-1">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                                <el-form-item prop="data.sort" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.sort" :min="1"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mt-5">
                                <el-form-item class="mb-0">
                                    <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                        <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                        <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                    </button>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import CustomCkeditor from "@/components/custom-ckeditor";

export default {
    props: ['paragraphID', 'questionID'],
    name: "index",
    components: {
        CustomCkeditor
    },
    data() {
        return {
            skeleton: true,
            form: {
                updateStatus: false,
                loading: false,
                translate: {},
                data: {
                    paragraph_id: this.paragraphID,
                    question_type_id: 1,
                    gains: [],
                    sort: 1
                },
                answers: []
            },
            question: {
                translate: {}
            },
            treeCategories: [],
            localQuestionID: undefined,
        }
    },
    computed: {
        defaultLanguage() {
            return this.$root.getLanguage('code', 'EN');
        },
        types() {
            return this.$store.state.module.paragraph.question.type.table.data;
        },
        gains() {
            return this.$store.state.module.exam.gain.table.data.map((gain) => {
                gain.translate =  this.resolveDatum(gain.translations, this.$root.defaultLanguage.id, 'language_id');

                return gain;
            });
        },
        validatedAnswers(){
            let answers = [];

            if(this.selectedQuestionType.code == 'NORMAL'){
                this.form.answers.forEach((answer, answerIndex) => {
                    if(answer.data.id){
                        answers.push({
                            label: this.sprintf(
                                this.$t('pages.module.paragraph.question.answer.titlePattern'),
                                [this.$root.questionLetters[answerIndex]]
                            ),
                            value: answer.data.id
                        });
                    }
                })
            }

            if(this.selectedQuestionType.code == 'TRUE_OR_FALSE'){
                answers = [
                    { label: this.$t('common.true'), value: 1},
                    { label: this.$t('common.false'), value: 2}
                ]
            }

            return answers;
        },
        selectedQuestionType(){
            let selectQuestionType = {};

            if(this.form.data.question_type_id){
                let found = this.types.filter((type) => type.id == this.form.data.question_type_id);
                if(found.length == 1){
                    selectQuestionType = found[0];
                }
            }

            return selectQuestionType;
        }
    },
    created() {
        this.localQuestionID = this.questionID;

        let answers = [];
        for(let i = 1; i <= 5; i++){
            answers.push({ translate: {}, data: {sort: i}})
        }

        this.form.answers = answers;
    },
    mounted() {
        if (this.localQuestionID && this.localQuestionID > 0) {
            this.loadQuestion();
        }

        this.$store.dispatch('module/exam/gain/get', {
            page: { pageSize: 9999 }
        });

        this.$store.dispatch('module/paragraph/question/type/get', {
            page: { pageSize: 9999 }
        });

        setTimeout(() => {
            this.skeleton = false;
        }, 0)
    },
    methods: {
        loadQuestion(questionID = this.localQuestionID) {
            this.axios.get(this.endpoints['module_paragraph_question'] + '/' + questionID).then((response) => {
                let data = response.data.data;

                data.translate = this.resolveDatum(data.translations, this.defaultLanguage.id, 'language_id');
                data.gains = data.gains.map((gain) => {
                    return gain.id
                });

                this.question = data;
                this.form.translate = data.translate;

                this.form.updateStatus = true;
                this.form.data = data;

                let answers = [];
                data.answers.forEach((answer) => {
                    answers.push({
                        translate: this.resolveDatum(answer.translations, this.defaultLanguage.id, 'language_id'),
                        data: answer,
                    })
                });

                if(answers.length) {
                    this.form.answers = answers;
                }
            })
        },
        onSubmit() {
            this.$refs.questionForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;
                    let formData = this.prepareFormData();

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['module_paragraph_question'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                if(this.selectedQuestionType.code == 'NORMAL' && this.form.answers.length){
                                    this.onSubmitAnswer().then((response) => {
                                        if(response.status){
                                            this.loadQuestion();
                                        }
                                    })
                                } else {
                                    this.loadQuestion();
                                }
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['module_paragraph_question'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                let questionID = response.data.data.id;

                                this.localQuestionID = questionID;
                                this.loadQuestion();

                                if(this.selectedQuestionType.code == 'NORMAL' && this.form.answers.length){
                                    this.onSubmitAnswer(questionID).then((response) => {
                                        if(response.status){
                                            this.loadQuestion(questionID);
                                        }
                                    })
                                } else {
                                    this.loadQuestion(questionID);
                                }
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        prepareFormData() {
            let formData = this.form.data;
            formData.language_id = this.defaultLanguage.id;

            return {...this.form.translate, ...formData}
        },
        async onSubmitAnswer(questionID = this.localQuestionID){
            return new Promise((resolve, reject) => {
                this.form.loading = true;
                let answerRequest = [];

                this.form.answers.forEach((answer) => {
                    answer.data.question_id = questionID;

                    let formData = this.prepareFormAnswerData(answer);

                    if (answer.data.id) {
                        answerRequest.push(this.axios.put(this.endpoints['module_paragraph_question_answer'] + '/' + answer.data.id, formData));
                    } else {
                        answerRequest.push(this.axios.post(this.endpoints['module_paragraph_question_answer'], formData));
                    }
                })

                this.axios.all(answerRequest).then(this.axios.spread((...responses) => {
                    let result = [];

                    for (let i = 0; i < responses.length; i++) {
                        let handleResponse = responses[i].data;

                        if (!handleResponse.status) {
                            break;
                        }

                        result.push(handleResponse.data);
                    }

                    if (result.length == responses.length) {
                        resolve({status: true, data: result});
                    } else {
                        reject('request_count_dont_match_with_response_count');
                    }
                })).catch((errors) => {
                    reject('occurred_any_error');
                })
            });
        },
        prepareFormAnswerData(answer) {
            let data = this.cloneData(answer.data);
            data.language_id = this.defaultLanguage.id;

            return {...answer.translate, ...data}
        },
        newAnswer(){
            let answerLength = this.form.answers.length;

            this.form.answers.push({
                translate: {},
                data: {
                    sort: answerLength ? (this.form.answers[answerLength - 1].data.sort + 1) : 1
                }
            })
        },
        deleteAnswer(answer, answerIndex){
            if(answer.data.id) {
                this.$store.dispatch("module/paragraph/question/answer/delete", {
                    id: [answer.data.id]
                }).then((successDeleted) => {
                    if(successDeleted){
                        this.loadQuestion();
                    }
                });
            } else {
                this.form.answers.splice(answerIndex, 1);
            }
        },
        backQuestion(){
            this.$emit('backQuestion');
        }
    }
}
</script>

<style>
.removeAnswerButton {
    padding: 8px 15px;
}
.removeAnswerButton .bi{
    font-size: 1.2rem;
}
</style>
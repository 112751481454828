<template>
    <div v-if="page.type == 'list'">
        <custom-table
            :title="$t('pages.module.paragraph.question.title')"
            :subTitle="$t('pages.module.paragraph.question.subTitle')"
            selectableRows
            rowKey="id"
            :items="table.data"
            :loading="table.loading"
            :columns="fields"
            :actions="actions"
            @action="handleClickAction"
            @selectableRows="handleSelectedRow">
            <template v-slot:type="{ row: record }">
                {{ resolveDatum(types, record.question_type_id, 'id').name }}
            </template>
            <template v-slot:correctAnswer="{ row: record }">
                <a v-if="record.question_type_id == 1 && record.correct_answer_id && record.answers.length" class="fw-bold text-gray-600 text-hover-primary cursor-pointer" v-on:click="showDetail('correctAnswerModal', record)">
                    {{ sprintf($t('pages.module.paragraph.question.answer.titlePattern'), [$root.questionLetters[getCorrectAnswer(record).foundIndex]]) }}
                </a>
                <span v-else>{{ getRecord('correctAnswer', record) ?? "-" }}</span>
            </template>
            <template v-slot:answerCount="{ row: record }">
                {{ getRecord('answerCount', record) ?? "-" }}
            </template>
            <template v-slot:content="{ row: record }">
                <div v-html="resolveDatum(record.translations, defaultLanguage.id, 'language_id').content" v-math></div>
            </template>
            <template v-slot:actions="{ row: record }">
                <div class="d-flex justify-content-end">
                    <el-tooltip :content="$t('pages.module.paragraph.question.answer.title')" placement="top" v-if="record.answers && record.answers.length">
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 cursor-pointer" v-on:click="showDetail('answerModal', record)">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/text/txt009.svg" />
                        </span>
                        </a>
                    </el-tooltip>
                    <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" v-on:click="questionDetail(record)">
                         <span class="svg-icon svg-icon-3">
                             <inline-svg src="/media/icons/duotune/art/art005.svg" />
                         </span>
                    </a>
                    <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                        <template #reference>
                            <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                <span class="svg-icon svg-icon-3">
                                    <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                </span>
                            </a>
                        </template>
                    </el-popconfirm>
                </div>
            </template>
        </custom-table>
        <div class="modal fade" id="kt_modal_correct_detail" ref="correctAnswerModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered mw-650px">
                <div class="modal-content">
                    <div class="modal-header" id="kt_modal_correct_detail_header">
                        <h2 class="fw-bolder">{{ $t("pages.module.paragraph.question.cols.correctAnswer") }}</h2>
                        <div id="kt_modal_correct_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                        </div>
                    </div>
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_correct_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <template v-if="showDetailData && showDetailData.record">
                                <div class="fv-row detail-item" v-html="resolveDatum(showDetailData.record.translations, defaultLanguage.id, 'language_id').content" v-math></div>
                            </template>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.correctAnswerModal)">{{ $t("btn.close") }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="kt_modal_answer_detail" ref="answerModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered mw-650px">
                <div class="modal-content">
                    <div class="modal-header" id="kt_modal_answer_detail_header">
                        <h2 class="fw-bolder">{{ $t("pages.module.paragraph.question.answer.title") }}</h2>
                        <div id="kt_modal_reservation_detail_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                        </div>
                    </div>
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_reservation_detail_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <template v-if="showDetailData">
                                <div class="fv-row detail-item" v-for="(answer, answerIndex) in showDetailData.answers" :key="answerIndex" :class="[answerIndex != 0 && 'mt-3']">
                                    <label class="fs-5 fw-bold mb-2 me-2">{{ sprintf($t('pages.module.paragraph.question.answer.titlePattern'), [$root.questionLetters[answerIndex]]) }}:</label>
                                    <span class="text-gray-500 fw-bold fs-6" v-html="resolveDatum(answer.translations, defaultLanguage.id, 'language_id').content" v-math></span>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.answerModal)">{{ $t("btn.close") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="page.type == 'show'">
        <Save v-if="page.type == 'show'" :paragraphID="paragraphID" :questionID="page.questionID" @backQuestion="showQuestionList"></Save>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
import Save from "@/components/module/paragraph/question/save";
export default {
    props: ['paragraphID'],
    name: "index",
    components: {
        CustomTable,
        Save
    },
    data(){
        return {
            page: {
                type: 'list',
            },
            paragraph: {},
            table: {
                loading: false,
                data: []
            },
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.paragraph.question.cols.content"),
                    scopedSlots: {customRender: "content"}
                },
                {
                    name: this.$t("pages.module.paragraph.question.cols.type"),
                    scopedSlots: {customRender: "type"}
                },
                {
                    name: this.$t("pages.module.paragraph.question.cols.correctAnswer"),
                    scopedSlots: {customRender: "correctAnswer"}
                },
                {
                    name: this.$t("pages.module.paragraph.question.cols.answerCount"),
                    scopedSlots: {customRender: "answerCount"},
                    class: ""
                },
                {
                    name: this.$t("common.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            selectedRowKeys: [],
            showDetailData: null,
        }
    },
    computed: {
        types() {
            return this.$store.state.module.paragraph.question.type.table.data;
        },
        defaultLanguage() {
            return this.$root.getLanguage('code', 'EN');
        },
    },
    created(){
        this.loadParagraph();
    },
    mounted() {
        this.$store.dispatch('module/paragraph/question/type/get', {
            page: { pageSize: 9999 }
        });
    },
    methods: {
        loadParagraph(){
            this.table.loading = true;

            this.axios.get(this.endpoints['module_paragraph'] + '/' + this.paragraphID).then((response) => {
                let data = response.data.data;
                this.paragraph = data;
                this.table.data = data.questions;
            }).finally(() => {
                this.table.loading = false;
            });
        },
        deleteRecord(id){
            this.$store.dispatch("module/paragraph/question/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
                if (successDeleted) {
                    this.loadParagraph();
                }
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.loadParagraph();
                    break;

                case "new":
                    this.page.type = 'show';
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        getCorrectAnswer(record) {
            let result = null;

            if(record.answers && record.answers.length && record.correct_answer_id){
                record.answers.sort((a,b) => {
                    return a.sort - b.sort;
                });

                let foundIndex = record.answers.findIndex((answer) => answer.id == record.correct_answer_id);

                if(foundIndex != -1){
                    result = {
                        foundIndex: foundIndex,
                        record: record.answers[foundIndex],
                    }
                }
            }

            return result;
        },
        showDetail(modalName, record) {
            this.showDetailData = record;

            if(modalName == 'correctAnswerModal'){
                this.showDetailData = this.getCorrectAnswer(record);
            }

            let modal = this.$refs[modalName];
            if(modal && this.showDetailData){
                this.showModal(modal);
            }
        },
        getRecord(type, record){
            if(this.types.length) {
                let recordTypeCode = this.resolveDatum(this.types, record.question_type_id, 'id').code;
                let splitted = recordTypeCode.split('_').map((part) => this.ucFirst(part));

                let methodName = 'getRecord' + splitted.join('');

                return this[methodName] ? this[methodName](type, record) : false
            }
        },
        getRecordNormal(type, record){
            let typeData = {
                answerCount: record.answers_count
            }

            return typeData[type] ?? null;
        },
        getRecordTrueOrFalse(type, record){
            let typeData = {
                answerCount: 2,
                correctAnswer: this.$t('common.' + (record.correct_answer_id == 1 ? 'true' : 'false'))
            }

            return typeData[type] ?? null;
        },
        questionDetail(record) {
            this.page.type = 'show';
            this.page.questionID = record.id;
        },
        showQuestionList(){
            this.page = { type: 'list' };
            this.loadParagraph();
        }
    }
}
</script>

<style>
.table p {
    margin-bottom: 0;
}
</style>
<template>
    <draggable class="list-group paragraph-container" :list="sentences" :disabled="quilEditor.show != undefined" group="people" item-key="id" @change="(event) => changedDraggableEvent(event)">
        <template #item="{ element, index }">
            <span class="paragraph-sentence" v-bind:class="sentences[index].translate.content == line && 'd-block h-20px'">
                <span v-if="sentences[index].translate.content == line"></span>
                <span class="sentence-text me-1" v-else-if="quilEditor.show != element.id" v-html="element.translate.content"></span>
                <span v-else class="inline-editor" v-bind:class="!sentences[index].translate.content.length && 'min-w-125px'">
                    <QuillEditor v-if="quilEditor.show == element.id" theme="bubble" :options="editorOption" v-model:content="sentences[index].translate.content" content-type="html"/>
                </span>
                <span v-if="indicatorShow">
                    <button type="button" class="btn btn-sm btn-icon btn-danger sentence-btn me-1" data-kt-menu-trigger="click" data-kt-menu-overflow="true" data-kt-menu-placement="bottom-start" data-kt-menu-flip="top-start">
                        <span class="svg-icon svg-icon-5 me-0">
                            <inline-svg src="/media/icons/duotune/general/gen053.svg"/>
                        </span>
                    </button>
                    <div :id="'sentence-dropdown-' + element.id" class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold pb-4 w-250px fs-6" data-kt-menu="true">
                        <div class="px-7 pt-5 pb-2 text-center">
                            <div class="fs-5 text-dark fw-bolder">{{ $t('pages.module.paragraph.save.editSentence.chooseAction') }}</div>
                        </div>
                        <div class="separator my-3"></div>
                        <div class="menu-item px-5">
                            <a v-if="quilEditor.show != element.id && element.translate.content != line" v-on:click="editSentence(element)" class="menu-link px-5">{{ $t('pages.module.paragraph.save.editSentence.sentenceMenu.edit') }}</a>
                        </div>
                        <div class="menu-item px-5">
                            <a v-on:click="newText(index, 'before')" class="menu-link px-5">{{ $t('pages.module.paragraph.save.editSentence.sentenceMenu.newSentenceBefore') }}</a>
                        </div>
                        <div class="menu-item px-5">
                            <a v-on:click="newText(index)" class="menu-link px-5">{{ $t('pages.module.paragraph.save.editSentence.sentenceMenu.newSentenceAfter') }}</a>
                        </div>
                        <div class="menu-item px-5">
                            <a v-on:click="newText(index, 'line')" class="menu-link px-5">{{ $t('pages.module.paragraph.save.editSentence.sentenceMenu.newLine') }}</a>
                        </div>
                        <div class="separator my-3"></div>
                        <div class="menu-item px-5">
                            <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteText(index, element)">
                                <template #reference>
                                    <a class="menu-link text-danger px-5">{{ $t('common.remove') }}</a>
                                </template>
                            </el-popconfirm>
                        </div>
                    </div>
                </span>
            </span>
        </template>
    </draggable>
</template>

<script>
import {QuillEditor} from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import draggable from "vuedraggable";
import {MenuComponent} from "@/assets/js/components/MenuComponent";
import sentence from "../../../store/module/paragraph/sentence";

export default {
    props: ['paragraphID'],
    name: "editParagraph",
    components: {
        QuillEditor,
        draggable
    },
    data() {
        return {
            paragraph: {},
            line: '{{LINE}}',
            editorOption: {
                placeholder: this.$t('pages.module.paragraph.save.editSentence.setSentence'),
                modules: {
                    keyboard: {
                        bindings: {
                            shift_enter: {
                                key: 13,
                                shiftKey: true,
                                handler: () => {
                                    this.editFinish()
                                }
                            },
                            enter: {
                                key: 13,
                                handler: () => {
                                    this.editFinish()
                                }
                            },
                            esc: {
                                key: 27,
                                handler: () => {
                                    this.cancelEditSentence();
                                }
                            }
                        }
                    },
                    toolbar: ['bold', 'italic', 'underline']
                }
            },
            quilEditor: {
                show: undefined,
                originalSentence: undefined,
            },
            sentences: [],
            indicatorShow: false
        }
    },
    computed: {
        defaultLanguage() {
            return this.$root.getLanguage('code', 'EN');
        },
    },
    created() {
        this.loadParagraph();
    },
    methods: {
        loadParagraph() {
            this.axios.get(this.endpoints['module_paragraph'] + '/' + this.paragraphID).then((response) => {
                let data = response.data.data;
                this.paragraph = data;

                this.setSentences();
            });
        },
        setSentences() {
            let sentences = [];
            if (this.paragraph.sentences) {
                this.paragraph.sentences.forEach((sentence) => {
                    sentence.translate = this.resolveDatum(sentence.translations, this.defaultLanguage.id, 'language_id')
                    sentences.push(sentence);
                });

                sentences.sort((a, b) => {
                    return a.sort - b.sort
                });
            }

            this.sentences = sentences

            this.reloadMenuComponents();
        },
        reloadMenuComponents() {
            this.indicatorShow = true;

            setTimeout(() => {
                MenuComponent.reinitialization();
            }, 500);
        },
        newText(index, type = 'after') {
            let uniqueID = this.uniqueID();
            let sentenceIndex = (type == 'before' ? index : (index + 1));

            let object = {
                new: true,
                id: uniqueID,
                sort: (sentenceIndex + 1),
                translate: {
                    language_id: this.defaultLanguage.id,
                    content: type == 'line' ? this.line : ""
                }
            };

            this.sentences.splice(sentenceIndex, 0, object);

            if (type != 'line') {
                this.quilEditor.show = uniqueID;

                setTimeout(() => {
                    this.indicatorShow = false;
                }, 0)
            } else {
                this.editFinish(uniqueID);
            }
        },
        deleteText(index, element) {
            this.$store.dispatch("module/paragraph/sentence/delete", {
                id: [element.id]
            }).then((successDeleted) => {
                if (successDeleted.length) {
                    this.loadParagraph();

                    let selector = '#sentence-dropdown-' + element.id;
                    let menu = MenuComponent.createInsance(selector);
                    menu.hide(document.querySelector(selector));
                }
            });
        },
        editSentence(element){
            this.quilEditor = this.cloneData({
                show: element.id,
                originalSentence: element.translate.content
            });

            setTimeout(() => {
                this.indicatorShow = false;
            }, 0)
        },
        cancelEditSentence(){
            let sentenceIndex = this.sentences.findIndex((sentence) => sentence.id == this.quilEditor.show);

            if (sentenceIndex >= 0) {
                if(this.quilEditor.originalSentence){
                    this.sentences[sentenceIndex].translate.content = this.cloneData(this.quilEditor.originalSentence);
                } else {
                    this.sentences.splice(sentenceIndex, 1);
                }
            }

            this.quilEditor = {
                show: undefined,
                originalSentence: undefined
            };

            this.reloadMenuComponents();
        },
        editFinish(sentenceID = this.quilEditor.show) {
            let sentence = this.sentences.filter((sentence) => sentence.id == sentenceID);

            if (sentence.length == 1) {
                let formData = {...sentence[0].translate, ...sentence[0]};

                if (sentence[0].new) {
                    formData.paragraph_id = this.paragraphID;

                    this.axios.post(this.endpoints['module_paragraph_sentence'], formData).then(response => {
                        this.onResponse(response.data, () => {
                            let data = response.data.data;
                            sentence[0].id = data.id;
                            this.submitSentenceMultiSort(false);
                            this.quilEditor.show = undefined;
                            this.reloadMenuComponents();
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data);
                    });
                } else {
                    this.axios.put(this.endpoints['module_paragraph_sentence'] + '/' + formData.id, formData).then(response => {
                        this.onResponse(response.data, () => {
                            this.quilEditor.show = undefined;
                            this.reloadMenuComponents();
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data);
                    });
                }
            }
        },
        changedDraggableEvent(event, customArgs, functionPrefix = 'Sentence') {
            let eventArr = Object.entries(event);
            let functionName = this[((eventArr[0][0]) + functionPrefix)];
            if (functionName) {
                functionName(eventArr[0][1], customArgs);
            }
        },
        movedSentence(args, parentDirectory) {
            this.submitSentenceMultiSort();
        },
        submitSentenceMultiSort(successAlert = true) {
            let items = this.sentences.map((item, itemIndex) => {
                return {
                    id: item.id,
                    sort: (itemIndex + 1)
                }
            });

            this.axios.post(this.endpoints['module_paragraph_sentence_multi_sort'], {items: items}).then(response => {
                this.onResponse(response.data, undefined, undefined, successAlert);
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            });
        }
    },
}
</script>

<style>
.paragraph-container {
    position: relative;
    display: block !important;
    flex-direction: unset !important;
    font-size: 14.5px;
}

.paragraph-container .paragraph-sentence {
    display: inline;
    vertical-align: middle;
    white-space: nowrap;
}
.paragraph-container .paragraph-sentence .sentence-text {
    white-space: break-spaces;
}

.paragraph-container .paragraph-sentence .sentence-text > * {
    display: inline;
    margin-bottom: 0;
}

.paragraph-container .paragraph-sentence .inline-editor {
    display: inline-grid;
}

.paragraph-container .paragraph-sentence .inline-editor .ql-editor {
    border-radius: 6px;
    border: 1px solid #ffc700;
}

.paragraph-container .paragraph-sentence .inline-editor .ql-editor p {
    display: inline;
}


.paragraph-sentence .sentence-btn {
    background-color: transparent;
    width: 22px !important;
    height: 22px !important;
}

.sentence-btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    fill: #F1416C;
}

</style>